export const measurementsOptions =
     [
        {"id": "back_bar", "name": "Back Bar", "value": 0},
        {"id": "brackets", "name": "Brackets", "value": 0},
        {"id": "bottom_bar", "name": "Bottom Bar", "value": 0},
        {"id": "fabric", "name": "Fabric", "value": 0},
        {"id": "overlap_width", "name": "Overlap Width", "value": 0},
        {"id": "overlap_drop", "name": "Overlap Drop", "value": 0},
        {"id": "top_tube_fabric_wrap", "name": "Top Tube Fabric Wrap", "value": 0},
        {"id": "bottom_bar_fabric_wrap", "name": "Bottom Bar Fabric Wrap", "value": 0},
        {"id": "gear_and_top_tube", "name": "Gear & Top Tube", "value": 0},
        {"id": "cover", "name": "Cover", "value": 0},
     ]

export function returnMeasurements(item, measurements) {
    if (item.attributes.type.includes('roller_blind')) return returnRollerBlindMeasurements(item, measurements)
    if (item.attributes.type.includes('zebra_blind')) return returnRollerBlindMeasurements(item, measurements)
    if (item.attributes.type.includes('vertical_blind')) return returnRollerBlindMeasurements(item, measurements)
    if (item.attributes.type.includes('curtain_blind')) return returnRollerBlindMeasurements(item, measurements)

    return []
}

export function returnRollerBlindMeasurements(item, measurements) {
    if (!Object.keys(measurements).length) return []
    const recessType = item.attributes.options[0].recess_type.toLowerCase().includes('outside') ? 'outside' : 'inside'
    const itemOptions = item.attributes.options[0]
    if (recessType === 'outside') return returnRollerBlindMeasurementsOutside(itemOptions, measurements)
    else if (recessType === 'inside') return returnRollerBlindMeasurementsInside(itemOptions, measurements)
}

function returnRollerBlindMeasurementsOutside(options, measurements) {
    const printingArray = []

    const finalBlindArray = []
    if (options.width && measurements.overlap_width !== undefined) finalBlindArray.push({title: 'Final Blind Width', unit: 'cm', value: Number(options.width) + Number(measurements.overlap_width)})
    if (options.drop && measurements.overlap_drop !== undefined) finalBlindArray.push({title: 'Final Blind Drop', unit: 'cm', value: Number(options.drop) + Number(measurements.overlap_drop)})
    if (finalBlindArray.length) printingArray.push({width: 'two', values: finalBlindArray})

    const detailsArray = []
    if (measurements.gear_and_top_tube !== undefined) detailsArray.push({title: 'Gear & Top Tube', value: measurements.gear_and_top_tube})
    if (options.width && measurements.overlap_width !== undefined && measurements.brackets !== undefined) detailsArray.push({title: 'Top Tube Width', unit: 'cm', value: (Number(options.width) + Number(measurements.overlap_drop)) - Number(measurements.brackets)})
    if (detailsArray.length) printingArray.push({width: 'two', values: detailsArray})

    const aluminiumCuttingArray = []
    if (options.fixing_type.includes('aluminium_back_bar') && options.width && measurements.overlap_width !== undefined && measurements.back_bar !== undefined) aluminiumCuttingArray.push({title: 'Back-bar Width', unit: 'cm', value: (Number(options.width) + Number(measurements.overlap_drop)) - Number(measurements.back_bar)})
    if (options.fixing_type.includes('aluminium_cover') && options.width && measurements.overlap_width !== undefined && measurements.cover !== undefined) aluminiumCuttingArray.push({title: 'Cover Width', unit: 'cm', value: (Number(options.width) + Number(measurements.overlap_drop)) - Number(measurements.cover)})
    if (options.width && measurements.overlap_width !== undefined && measurements.bottom_bar !== undefined) aluminiumCuttingArray.push({title: 'Bottom Bar Width', unit: 'cm', value: (Number(options.width) + Number(measurements.overlap_drop)) - Number(measurements.bottom_bar)})
    if (aluminiumCuttingArray.length) printingArray.push({width: 'two', values: aluminiumCuttingArray})

    const fabricCuttingArray = []
    if (options.width && measurements.overlap_width !== undefined && measurements.fabric !== undefined) fabricCuttingArray.push({title: 'Fabric Cut Width', unit: 'cm', value: (Number(options.width) + Number(measurements.overlap_width)) - Number(measurements.fabric)})
    if (options.drop && measurements.overlap_drop !== undefined && measurements.top_tube_fabric_wrap !== undefined) fabricCuttingArray.push({title: 'Fabric Cut Drop', unit: 'cm', value: (Number(options.drop) + Number(measurements.overlap_drop)) + Number(measurements.top_tube_fabric_wrap)})
    if (fabricCuttingArray.length) printingArray.push({width: 'two', values: fabricCuttingArray})

    return printingArray
}

function returnRollerBlindMeasurementsInside(options, measurements) {
    const printingArray = []

    const finalBlindArray = []
    if (options.width && options.width_clearance !== undefined) finalBlindArray.push({title: 'Final Blind Width', unit: 'cm', value: Number(options.width) - Number(parseFloat(options.width_clearance))})
    if (options.drop) finalBlindArray.push({title: 'Final Blind Drop', unit: 'cm', value: Number(options.drop)})
    if (finalBlindArray.length) printingArray.push({width: 'two', values: finalBlindArray})

    const detailsArray = []
    if (measurements.gear_and_top_tube !== undefined) detailsArray.push({title: 'Gear & Top Tube', value: measurements.gear_and_top_tube})
    if (options.width && options.width_clearance !== undefined && measurements.brackets !== undefined) detailsArray.push({title: 'Top Tube Width', unit: 'cm', value: (Number(options.width) - Number(parseFloat(options.width_clearance))) - Number(measurements.brackets)})
    if (detailsArray.length) printingArray.push({width: 'two', values: detailsArray})

    const aluminiumCuttingArray = []
    if (options.fixing_type.includes('aluminium_back_bar') && options.width && options.width_clearance !== undefined && measurements.back_bar !== undefined) aluminiumCuttingArray.push({title: 'Back-bar Width', unit: 'cm', value: (Number(options.width) - Number(parseFloat(options.width_clearance))) - Number(measurements.back_bar)})
    if (options.fixing_type.includes('aluminium_cover') && options.width && options.width_clearance !== undefined && measurements.cover !== undefined) aluminiumCuttingArray.push({title: 'Cover Width', unit: 'cm', value: (Number(options.width) - Number(parseFloat(options.width_clearance))) - Number(measurements.cover)})
    if (options.width && options.width_clearance !== undefined && measurements.bottom_bar !== undefined) aluminiumCuttingArray.push({title: 'Bottom Bar Width', unit: 'cm', value: (Number(options.width) - Number(parseFloat(options.width_clearance))) - Number(measurements.bottom_bar)})
    if (aluminiumCuttingArray.length) printingArray.push({width: 'two', values: aluminiumCuttingArray})

    const fabricCuttingArray = []
    if (options.width && options.width_clearance !== undefined && measurements.fabric !== undefined) fabricCuttingArray.push({title: 'Fabric Cut Width', unit: 'cm', value: (Number(options.width) + Number(parseFloat(options.width_clearance))) - Number(measurements.fabric)})
    if (options.drop && measurements.top_tube_fabric_wrap !== undefined && measurements.bottom_bar_fabric_wrap !== undefined) fabricCuttingArray.push({title: 'Fabric Cut Drop', unit: 'cm', value: (Number(options.drop) + Number(measurements.top_tube_fabric_wrap)) + Number(measurements.bottom_bar_fabric_wrap)})
    if (fabricCuttingArray.length) printingArray.push({width: 'two', values: fabricCuttingArray})

    return printingArray
}